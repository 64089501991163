<template>
  <div class="wrap">
    <div class="header">
      <img :src="townMsg.picture" class="town-pic"/>
      <img src="/images/detail/title.png" class="title-img">
    </div>
    <div class="center-box">

      <div>
        <NewsHeader/>
        <div class="main-content" v-html="html" ></div>
      </div>
      <div class="center-right-box">
          <ul>
            <li v-for="(v,index) in rightItemDatas" :key="index">
              <div class="right-item">
                <div class="right-item-top-box">
                  <img src="/images/activity/banner.png" class="right-img">
                  <div>
                    <p class="right-item-title">
                      故事标题故事标题故事标题故事标题20字以内
                    </p>
                    <p class="right-item-author">作者:飞翔的懒猫</p>
                  </div>
                </div>
                <p class="right-item-desc">
                  内容简介内容简介内容简介内容简介内容简介内容
                </p>
                <div class="right-item-bottom-box">
                  <div class="right-item-bottom-line"/>
                  <p class="right-item-date">2023-12-12</p>
                </div>
              </div>
            </li>
          </ul>
      </div>

    </div>
  </div>
</template>
<script>

import {hideTextTag, formatDate} from '@/utils/tools'
import api from '@/api'
import NewsHeader from '@/components/common/newsheader'
import {ref} from 'vue'

export default {
  name: '',
  components: {
    NewsHeader
  },
  data () {
    return {
      id: '',
      visible: false,
      townMsg: {},
      news: [],
      industry: [],
      interview: {},
      album: [],
      vr: {}
    }
  },
  methods: {
    goPage (url) {
      if (url && url.includes('http') || url?.includes('www')) {
        window.open(url, '_target')
      } else {
        this.$router.push(url)
      }

    },
    async getData () {
      let id = this.$route.query.id
      const getStory = async () => {
        let res = await api.getStory({
          townId: id
        })
        this.townMsg.story = res ?? {}
      }
      const getNews = async () => {
        let res = await api.getNewsList({
          pageNum: 1,
          pageSize: 6,
          townId: id
        })
        this.news = res.records?.map(item => ({
          ...item,
          publishDate: formatDate(item.publishDate)
        })) ?? []
      }
      const getVr = async () => {
        let res = await api.getVrList({
          pageNum: 1,
          pageSize: 1,
          townId: id
        })
        this.vr = res?.records[0] ?? {}
      }
      const getIndustry = async () => {
        let res = await api.getIndustryList({
          pageNum: 1,
          pageSize: 3,
          townId: id
        })
        this.industry = res?.records?.map(item => ({
          ...item,
          content: hideTextTag(item.content),
          publishDate: formatDate(item.publishDate)
        })) ?? []
      }
      const getAlbum = async () => {
        let res = await api.getAlbumList({
          pageNum: 1,
          pageSize: 10,
          townId: id
        })
        this.album = res?.records ?? []
      }
      const getInterview = async () => {
        let res = await api.getInterview({
          townId: id
        })
        this.interview = res ?? {}
      }


      let res = await api.getTown({
        id
      })
      this.townMsg = {
        voice: res?.voice ?? '',
        picture: res?.picture ?? '',
        introduction: res?.introduction ?? ''
      }
      if (res.story) {
        getStory()
      }
      if (res.trend) {
        getNews()
      }
      if (res.vr) {
        getVr()
      }
      if (res.industry) {
        getIndustry()
      }
      if (res.album) {
        getAlbum()
      }
      if (res.talk) {
        getInterview()
      }
    }
  },
  created () {
    let id = this.$route.query.id
    if (id) {
      this.id = id
    }
    this.getData()
  },
  setup() {
    const html = ref('<p>增温</p>')
    const rightItemDatas = ref(['1','2','3'])
    return {
      html,
      rightItemDatas
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {


  .header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .town-pic {
      width: 100%;
      height: 4.48rem;
    }

    .title-img {
      width: 6.4rem;
      height: 1.6rem;
      position: absolute;
    }
  }

  .center-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

  }
  .center-right-box {
    height: 10rem;
    //background: red;
    width: 3.47rem;
    position: absolute;
    right: 0;
    padding: 0.11rem;
    box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);
  }
  .main-content {
    word-break: break-all;
    margin-top: 0.5rem;

    & /deep/ img {
      width: 100%;
      max-width: 100%;
    }
  }
  .right-item {
    display: flex;
    flex-direction: column;

    .right-img {
      width: 1.41rem;
      height: 0.93rem;
      margin-right: 0.06rem;
    }

    .right-item-top-box{
      display: flex;
      .right-item-title {
        font-size: 0.14rem;
        @include text-moreLine(2)
      }
      .right-item-author {
        font-size: 0.12rem;
        color: #999999;
        margin-top: 0.02rem;
        @include text-moreLine(1)
      }

    }

    .right-item-desc {
      font-size: 0.14rem;
      color: #999999;
      @include text-moreLine(1)
    }

    .right-item-bottom-box {
      display: flex;
      align-items: center;

      .right-item-bottom-line {
        height: 0.01rem;
        background: #E1E1E1;
        flex: 1;
      }
      .right-item-date {
        font-size: 0.12rem;
        color: #999999;
        margin-right: 0;
      }
    }

  }

}
</style>
